import isString from 'lodash/isString';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import Plyr from 'plyr';
import dom from '../../../wrapper/DomWrapper';
import { subscribeToDebouncedResizeChanging } from '../../../observer/resizeObserver';
import { VIDEO_PROVIDER_NAMES } from '../../../../constants';
import {
  PREVENT_BLACK_LINES_RESERVE,
  VIDEO_RESIZE_DEBOUNCE,
  YOUTUBE_BRANDING_RESERVE,
  YOUTUBE_RATIO,
} from '../constants';

class ProviderWrapper {
  constructor(selector, settings) {
    this.video = selector;
    this.dataParams = settings;
    this.videoWidth = 0;
    this.videoHeight = 0;
  }

  renderVideoOnPage = (exist = true) => {
    const {
      hash,
      isBackground
    } = this.dataParams;
    const elVideoContainer = dom.getElement(`#v-${hash}`, this.video);
    const elVideo = dom.createElement('div');
    const elStyle = dom.createElement('style');

    dom.addHtml(elStyle, `
      .plyr--video {
        background: transparent!important;
        overflow: unset;
      }
      .plyr__video-wrapper{
        background: transparent!important;
        padding-bottom: 56.25%!important;
        ${isBackground ? 'overflow: unset' : ''}
      }
      .plyr__video-embed__container{
        transform: translateY(-38.28125%) !important;
      }
      .not-background{
        transform: translateY(0) !important;
        padding-bottom: 56.25% !important;
      }
    `);

    dom.document.head.appendChild(elStyle);

    if (!exist) {
      this.showVideoError();

      return;
    }

    dom.addHtml(elVideo, `
      <div id="player">
        <video id="video-${hash}" ></video>
      </div>
    `);

    elVideoContainer.appendChild(elVideo);
  };

  addPreviewImage = (player) => {
    const { hash } = this.dataParams;
    const previewContainerId = `#preview-container-${hash}`;
    this.elPreview = dom.getElement(previewContainerId, this.video);

    if (!this.elPreview) return;

    dom.on(this.elPreview, 'click', () => {
      player.play();
    });
  };

  showVideoError = (e) => {
    const errorDetailMethod = e?.detail?.method;

    /**
     * [SP-134244] Check for e.detail.method === 'setPlaybackRate' need for the filtering
     * Plyr customEvent, that call error event. That`s because Vimeo player
     * don`t provide set playback rate functional.
     * At the moment there are no ways to influence this error in Plyr.
     *
     * example of the error:
     * e.detail: {
         message: "Setting the playback rate is not enabled for this video."
         method: "setPlaybackRate"
         name: "Error"
         ...
       }
     *
     * [SP-158603] error {isTrusted: false} occurs for some unknown reason, but it is
     * caught on iPhone X and newer models, because of this the video widget broke
     */
    if (!this.video || e?.isTrusted === false || errorDetailMethod === 'setPlaybackRate') return;

    const errorWrapperEl = dom.getElement('.video-error-wrapper', this.video);
    const previewEl = dom.getElement('.video__preview-container', this.video);

    dom.show(errorWrapperEl);
    dom.hide(previewEl);
  };

  connectVideoDefault = () => {
    const {
      hash,
      isBackground,
      autoplay,
    } = this.dataParams;
    const playerId = `#video-${hash}`;
    const options = {
      controls: null,
      loadSprite: false,
      muted: isBackground ? true : !!autoplay,
      autoplay: isBackground ? 1 : autoplay,
      loop: {
        active: !!isBackground,
      },
      autopause: 0,
    };

    this.player = new Plyr(playerId, options);
    this.player.on('ready', this.onPlayerReady);
    this.player.on('playing', this.onPlayerPlaying);
    this.player.on('ended', this.onPlayerEnded);
    this.player.on('error', this.showVideoError);

    subscribeToDebouncedResizeChanging(this.video, this.resizeVideo, VIDEO_RESIZE_DEBOUNCE);

    return this.player;
  };

  // eslint-disable-next-line class-methods-use-this
  getVideoSize(provider, video) {
    const {
      width,
      height
    } = video;

    switch (provider) {
      case VIDEO_PROVIDER_NAMES.YOUTUBE:
        return {
          width,
          height,
        };
      case VIDEO_PROVIDER_NAMES.VIMEO:
        return {
          height: 240,
          width: 426,
        };
      case VIDEO_PROVIDER_NAMES.DAILYMOTION:
        return {
          height: 270,
          width: 480,
        };
      default:
        return {
          width,
          height,
        };
    }
  }

  getLoopTimeoutTime = () => {
    if (!this.player) return 0;

    return Math.round((this.player.duration - this.player.currentTime) * 1000) - 1500;
  };

  getTimeCode = (startTime) => {
    const isStartTimeInSeconds = !isString(startTime);

    if (isStartTimeInSeconds) return Number(startTime) || 0;

    const re = /(\d{1,2}h)?(\d{1,2}m)?(\d{1,2}s)?/;
    const params = re.exec(startTime);
    const hours = parseInt(params[1], 10) || 0;
    const minutes = parseInt(params[2], 10) || 0;
    const seconds = parseInt(params[3], 10) || 0;

    return hours * 3600 + minutes * 60 + seconds || 0;
  };

  onPlayerReady = () => {
    this.resizeVideo();
    this.player.volume = 0.8;

    if (this.dataParams.autoplay) {
      this.player.volume = 0;
      this.player.muted = true;

      if (this.dataParams.provider === 'youtube') {
        this.player.play();
      }
    }
  };

  onPlayerEnded = () => {
    clearTimeout(this.loopTimeoutId);
  };

  onPlayerPlaying = () => {
    const isBackground = get(this, ['dataParams', 'isBackground'], false);
    const videoParams = get(this, ['dataParams', 'videoParams'], {});

    this.hidePreview();

    if (!isBackground || !this.player) return;

    const playlistId = get(videoParams, ['list'], null);
    const timeout = this.getLoopTimeoutTime();
    const cb = playlistId
      ? this.playNextVideoInPlaylist
      : this.playSingleVideo;

    this.loopTimeoutId = setTimeout(cb, timeout);
  };

  playNextVideoInPlaylist = () => {
    if (!this.player) return;

    this.player.nextVideo();
  };

  playSingleVideo = () => {
    const startTime = get(this, ['dataParams', 'videoParams', 't'], null);

    this.player.currentTime = this.getTimeCode(startTime);
  };

  hidePreview = () => {
    if (this.elPreview) dom.hide(this.elPreview);
  };

  resizeVideo = () => {
    const {
      provider,
      isSliderBg,
      isBackground
    } = this.dataParams;
    const { video } = this;
    // eslint-disable-next-line no-nested-ternary
    const section = isSliderBg
      ? video.parentNode.parentNode
      : isBackground
        ? video.parentNode
        : video;
    const sectionSize = section.getBoundingClientRect();
    const elVideo = dom.getElement('iframe', this.video);
    const sizeMultiplier = isSliderBg && provider === VIDEO_PROVIDER_NAMES.YOUTUBE
      ? 1 + PREVENT_BLACK_LINES_RESERVE : 1;
    const videoSize = this.getVideoSize(provider, elVideo);

    const {
      height: sectionHeight,
      width: sectionWidth
    } = sectionSize;
    const { width: videoWidth } = videoSize;
    let { height: videoHeight } = videoSize;
    const videoRatio = +(videoWidth / videoHeight).toFixed(2);

    if (provider === VIDEO_PROVIDER_NAMES.YOUTUBE
      && videoRatio < YOUTUBE_RATIO - 0.03
      && isSliderBg) {
      videoHeight -= YOUTUBE_BRANDING_RESERVE * 2;
    }

    let newVideoHeight;
    let newVideoWidth;

    const widthRatio = sectionWidth / videoWidth;
    const heightRatio = sectionHeight / videoHeight;

    // if height gap is higher than width gap
    if (heightRatio > widthRatio) {
      // video height times 1.1
      newVideoHeight = Math.round(sectionHeight * sizeMultiplier);
      // value of video width adjusted based on ratio between section and video height times 1.1
      newVideoWidth = Math.round(heightRatio * videoWidth * sizeMultiplier);
    } else {
      // video width times 1.1
      newVideoWidth = Math.round(sectionWidth * sizeMultiplier);
      // value of video height adjusted based on ratio between section and video width times 1.1
      newVideoHeight = Math.round(widthRatio * videoHeight * sizeMultiplier);
    }

    if (provider === VIDEO_PROVIDER_NAMES.YOUTUBE && isSliderBg) {
      newVideoHeight += YOUTUBE_BRANDING_RESERVE * 2;
    }

    if (isEqual(this.videoWidth, newVideoWidth) && isEqual(this.videoHeight, newVideoHeight)) {
      return;
    }

    this.videoWidth = newVideoWidth;
    this.videoHeight = newVideoHeight;

    const videoOffsetTop = (newVideoHeight - sectionHeight) / 2;
    const videoOffsetLeft = (newVideoWidth - sectionWidth) / 2;

    if (isEqual(provider, VIDEO_PROVIDER_NAMES.VIMEO)) {

      if (!isBackground) {
        elVideo.parentNode.classList.add('not-background');
      }

      dom.updateStyle(elVideo, {
        height: '100%',
        width: '100%',
        top: '0px',
        left: '0px',
      });
    } else {
      dom.updateStyle(elVideo, {
        height: `${newVideoHeight}px`,
        width: `${newVideoWidth}px`,
        top: `-${videoOffsetTop}px`,
        left: `-${videoOffsetLeft}px`,
      });
    }
  };
}

export default ProviderWrapper;
